import Header from "../../ui/header/Header"
import React, { FC } from "react"
import { MasterTemplateProps } from "../../../types"
import Navigation from "../navigation/Navigation"
import { useLocation } from "react-router-dom"
import "../../styles/App.css"
import ChatAssistant from "../chat-assistant/ChatAssistant"
import AdminNavigation from "../navigation/admin/AdminNavigation.tsx"

const MasterTemplate: FC<MasterTemplateProps> = (props) => {
  const location = useLocation()
  const path = location.pathname
  const segments = path.split("/").filter(Boolean)

  const checkPublicPage = () => {
    return (
      path.includes("login") ||
      path.includes("forgot-password") ||
      path.includes("invitation") ||
      path.includes("artist/") ||
      path.includes("smart-link/") ||
      path.includes("start-your-journey")
    )
  }

  const checkFullPage = () => {
    return (
      path.includes("login") ||
      path.includes("forgot-password") ||
      path.includes("asset-registry") ||
      path.includes("release-registry") ||
      path.includes("smart-link") ||
      path.includes("invitation") ||
      path.includes("artist-space") ||
      path.includes("artist") ||
      path.includes("analytics") ||
      path.includes("catalog/new-release") ||
      path.includes("start-your-journey")
    )
  }

  const checkHomePage = () => {
    return (
      !path.includes("account") &&
      !path.includes("notifications") &&
      (!path.includes("catalog/") || (path.includes("catalog") && segments.length === 1))
    )
  }

  const checkAdminPage = () => {
    return path.includes("admin") && !(path.includes("catalog") && segments.length === 3)
  }

  const renderContainer = () => {
    const width = checkFullPage() || checkAdminPage() ? "100%" : checkHomePage() ? "80%" : "68%"

    const margin = width === "80%" ? 72 : 0

    return (
      <div
        style={{
          marginLeft: margin,
          marginRight: margin,
          width,
          overflowY: "scroll"
        }}
      >
        {props.children}
      </div>
    )
  }

  const renderNavigation = () => {
    const width = checkAdminPage() ? "45px" : checkHomePage() ? "20%" : "16%"
    return (
      <div style={{ width, height: checkAdminPage() ? "100vh" : "calc(100vh - 64px)" }}>
        {checkAdminPage() && <AdminNavigation />}
        {checkHomePage() && !checkAdminPage() && <Navigation />}
      </div>
    )
  }

  return (
    <>
      {!checkFullPage() && !checkAdminPage() && <Header />}
      <div className={"flex"} style={{ width: "100%", overflow: "hidden" }}>
        {!checkFullPage() && renderNavigation()}
        {renderContainer()}
        {!checkPublicPage() && <ChatAssistant />}
      </div>
    </>
  )
}

export default MasterTemplate
