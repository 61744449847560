import React, { FC, ReactNode, useEffect, useRef, useState } from "react"
import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { LCReleaseForm, SelectOption, User } from "../../../../../../types"
import { releases, users } from "../../../../../../apis"
import { hashids, mapListToCamelCase } from "../../../../../../utility"
import ErrorModal from "../../modal/ErrorModal"
import Loading from "../../../../../ui/Loading"
import {
  notAllowedProductAttributes,
  notAllowedProductRelationships,
  processCatalogNoString
} from "../../../../../../utility/newReleaseHelpler"
import { useNavigate } from "react-router-dom"
import { errorFilledToastOptions } from "../../../../../../constants"
import { toast } from "react-toastify"
import { lang } from "../../../../../../constants/languages"
import { NewReleaseActions, TakedownActionTabs } from "../../../../../../actions/types"
import ConfirmationDialog from "../../../../../ui/modals/ConfirmationDialog"

type TakedownProps = {
  user: User
  setSelectedPage: (selectedPage: number) => void
  selectedTab: number
  setSelectedTab: (value: number) => void
  selectedArtist?: SelectOption
  setSelectedArtist: (selectedArtist?: SelectOption) => void
}

// Spotify Track
type Track = {
  id: string
  name: string
  isrc: string
  artists_list: string[]
  track_number: number
  explicit: boolean
}

type Artist = {
  name: string
}

// Spotify Album
type Album = {
  id: string
  name: string
  cover: string
  releaseDate: string
  totalTracks: number
  artistsList: Artist[]
}

const Takedown: FC<TakedownProps> = (props) => {
  const { user, setSelectedPage, selectedTab, setSelectedTab, selectedArtist, setSelectedArtist } = props

  const [errorText, setErrorText] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SelectOption[]>([])
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [searchArtistText, setSearchArtistText] = useState("")
  const [searchAlbumText, setSearchAlbumText] = useState("")
  const [albumToSelect, setAlbumToSelect] = useState<Album>()
  const [albums, setAlbums] = useState<Album[]>([])
  const [hasNext, setHasNext] = useState()
  const [offset, setOffset] = useState(0)
  const [isUpload, setIsUpload] = useState(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)

  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)

  const fileInputRef = useRef(null)

  useEffect(() => {
    if (searchParams && searchParams.get("upload")) {
      setIsUpload(true)
    }
  }, [searchParams])

  /* useEffect(() => {
    // LOCAL TESTING
    onSelectAlbumForTakedown({ id: "1" })
  }, []) */

  useEffect(() => {
    props.setSelectedArtist(undefined)
    setSelectedTab(TakedownActionTabs.SPOTIFY_ARTIST)
  }, [])

  const fetchAlbums = (artistId: string, offset: number, albums: Album[], spAlbumIds?: string[]) => {
    const albumSpIdParam = spAlbumIds ? `&sp_album_ids=${spAlbumIds.join(",")}` : ""
    setLoading(true)
    users(`/artist_albums_spotify/?artist_id=${artistId}&offset=${offset}${albumSpIdParam}`, "GET").then((resp) => {
      setLoading(false)
      const results = mapListToCamelCase(resp.data.results)
      setAlbums([...albums, ...results])
      setHasNext(resp.data.next)
    })
  }

  const onSearch = (text: string, retry: boolean) => {
    if (!text) {
      setLoading(false)
      return
    }

    setLoading(true)
    users(`/search_artist_spotify/?search=${text}`, "GET").then((resp) => {
      setLoading(false)
      setOptions(resp.data.results)
    })
  }

  const handleInputChange = (text: string) => {
    setSearchArtistText(text)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const newTimeoutId = setTimeout(() => {
      // Call onSearch after 1 second
      onSearch(text, true)
    }, 1000)

    setTimeoutId(newTimeoutId)
  }

  const onClickArtist = (option: SelectOption) => {
    setSelectedArtist(option)
    setSearchArtistText("")
    setOffset(0)
    if (!isUpload) {
      fetchAlbums(option.value.toString(), 0, [])
    }

    setSelectedTab(TakedownActionTabs.SELECT_ALBUM)
  }

  const onLoadMore = () => {
    if (!selectedArtist) {
      return
    }
    setOffset(offset + 50)
    fetchAlbums(selectedArtist.value.toString(), offset + 50, albums)
  }

  const handleFileChange = async (event: any) => {
    setLoading(true)
    const file = event.target.files[0]
    if (!file || !selectedArtist) return

    const reader = new FileReader()
    reader.onload = (e) => {
      const text = e.target?.result as string // Explicitly assert as string
      if (!text) return

      const rows = text.split("\n").map((row) => row.trim())

      if (rows.length < 2) return

      // Extract headers and find the index of "track_album_albumId"
      const headers = rows[0].split(",")
      const albumIdIndex = headers.indexOf("track_album_albumId")

      if (albumIdIndex === -1) {
        toast.error("Column 'track_album_albumId' not found.")
        return
      }

      // Extract and filter unique album IDs
      const albumIds = new Set<string>()
      for (let i = 1; i < rows.length; i++) {
        const columns = rows[i].split(",")
        if (columns[albumIdIndex]) {
          albumIds.add(columns[albumIdIndex].trim())
        }
      }

      fetchAlbums(selectedArtist.value.toString(), 0, [], [...albumIds])

      setLoading(false)
    }

    reader.readAsText(file)
  }

  const renderSearchResults = () => {
    return (
      <div className="render-search-results-div">
        <div className="mt-6 pb-8" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.20)" }}>
          <div className="block">
            {options.length > 0 &&
              options.map((option) => {
                return (
                  <div
                    className="flex mb-3"
                    style={{ cursor: "pointer", backgroundColor: "#F5F5F5", borderRadius: 10 }}
                    key={`${option.value}${option.type}`}
                    onClick={() => onClickArtist(option)}
                  >
                    <img
                      className="my-2.5 ml-3.5 mr-4"
                      style={{ width: 36, height: 36, borderRadius: 50 }}
                      src={option.cover || "/account_icons/avatar.svg"}
                    />
                    <div className={"grid items-center"}>
                      <KSpan
                        text={option.label?.toString() || ""}
                        color="#000"
                        fontSize={16}
                        fontWeight={500}
                        lineHeight="24px"
                        letterSpacing="-0.176px"
                      />
                    </div>
                  </div>
                )
              })}
            {options.length === 0 && (
              <React.Fragment>
                <div className="flex flex-row justify-between items-center gap-2.5 mt-6">
                  <hr style={{ flexGrow: 1 }} />
                  <KSpan
                    text={lang.common.or.toUpperCase()}
                    fontWeight={500}
                    fontSize={11}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                    color="#111"
                  />
                  <hr style={{ flexGrow: 1 }} />
                </div>
                <div
                  style={{
                    height: 196,
                    backgroundColor: "#F5F5F5",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 24,
                    padding: 24,
                    alignContent: "center",
                    borderRadius: "10px",
                    marginTop: "24px"
                  }}
                >
                  <img src={"/account_icons/music-album.svg"} />
                  <div className="flex flex-col gap-3 justify-center items-center w-full">
                    <KSpan text={lang.catalog.new_release.no_results} />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderSearchBox = () => {
    return (
      <div className="render-search-box">
        <div>
          <KInput
            value={searchArtistText}
            onChange={(value) => handleInputChange(value)}
            placeholder={lang.catalog.new_release.artist_name_placeholder}
            leftIcon={"/search.svg"}
            padding="14px"
            gap="8px"
          />
        </div>
        {searchArtistText && (
          <div>
            {loading ? (
              <div
                style={{
                  height: 196,
                  backgroundColor: "#F7F7F7",
                  textAlign: "center",
                  justifyItems: "center",
                  gap: 24,
                  padding: 24,
                  alignContent: "center",
                  borderRadius: "10px",
                  marginTop: 24
                }}
                className={"grid"}
              >
                <img src={"/account_icons/music-album.svg"} alt="music-album" />
                <div className="flex flex-row">
                  <KSpan text={lang.catalog.new_release.searching_in_database} />
                  <span className="dots"></span>
                </div>
              </div>
            ) : (
              renderSearchResults()
            )}
          </div>
        )}
      </div>
    )
  }

  const saveProductAndTracks = (
    releaseForm: LCReleaseForm,
    labelName: string,
    trackList: Track[],
    isMulti: boolean
  ): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const productRelationships: Record<string, any> = {}
      Object.entries(releaseForm.product.data.relationships).forEach(([key, value]) => {
        if (value["data"] && !notAllowedProductRelationships.includes(key)) {
          productRelationships[key] = { data: value["data"] }
        }
      })

      const productAttributes: Record<string, any> = {}
      Object.entries(releaseForm.product.data.attributes).forEach(([key, value]) => {
        if (value && !notAllowedProductAttributes.includes(key)) productAttributes[key] = value
      })

      const labelId = releaseForm.product.data.relationships.label?.data?.id
      const labelInternalReference = processCatalogNoString(productAttributes["name"], labelId)

      const filteredReleaseForm = {
        label_name: labelName,
        product: {
          data: {
            id: releaseForm.product.data.id,
            attributes: {
              ...productAttributes,
              "label-internal-reference": labelInternalReference,
              "is-compilation": false
            },
            relationships: productRelationships,
            type: "products"
          }
        },
        is_urgent: false,
        takedown: true
      }

      const errorMessages: string[] = []

      try {
        const productResponse = await releases(`/release/label_camp_product_save/`, "post", filteredReleaseForm)

        if (!productResponse.data.success) {
          errorMessages.push(
            `${lang.catalog.new_release.error.product_response}: ${JSON.stringify(productResponse.data.result)}`
          )
        }

        if (productResponse.data.release_id && productResponse.data.result.product.data.id) {
          const productId = productResponse.data.result.product.data.id

          for (const track of trackList) {
            try {
              const requestBody = {
                track: {
                  data: {
                    relationships: {
                      product: { data: { id: productId, type: "products" } }
                    },
                    attributes: {
                      "volume-number": 1,
                      "track-number": track["track_number"],
                      title: track["name"],
                      "isrc-code": track["isrc"],
                      artist: track["artists_list"],
                      "parental-advisory": track["explicit"] || false
                    },
                    type: "tracks"
                  }
                }
              }

              setLoading(true)
              const trackResponse = await releases(`/release/label_camp_track_create/`, "post", requestBody)

              if (!trackResponse.data.success) {
                errorMessages.push(
                  `${lang.catalog.new_release.error.track_response}: ${JSON.stringify(trackResponse.data.result)}`
                )
              }
            } catch (err) {
              errorMessages.push(`${lang.catalog.new_release.error.track_error}: ${err}`)
            }
          }

          const productTitle = releaseForm.product.data.attributes["name"]
          if (isMulti) {
            if (productResponse.data.success) {
              toast.success(`Takedown successful for album: ${productTitle}`)
            } else {
              toast.error(`Failed to takedown album: ${productTitle}`, errorFilledToastOptions)
            }
          } else {
            if (productResponse.data.release_id) {
              try {
                const resp = await users("/use_takedown_credit/", "PUT", {})
                if (resp.data.success) {
                  navigate(
                    `/catalog/asset-registry/${hashids.encode(productResponse.data.release_id.toString() || "")}`
                  )
                } else {
                  setErrorText(lang.catalog.new_release.error.no_credit)
                }
              } catch (err) {
                errorMessages.push(`Error using takedown credit: ${err}`)
              }
            }
          }
        }
      } catch (e) {
        console.error(lang.catalog.new_release.error.error_on_saving_product)
        errorMessages.push(e)
      } finally {
        setLoading(false)
        if (errorMessages.length > 0) {
          setErrorText(errorMessages.join("\n\n"))
          toast.error(errorMessages.join(" ---- "), errorFilledToastOptions)
        }
        resolve()
      }
    })
  }

  const onSelectAlbumForTakedown = async (album: Album, isMulti: boolean) => {
    return new Promise<void>((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = "anonymous" // Allow cross-origin images
      img.src = album.cover

      img.onload = async () => {
        try {
          const canvas = canvasRef.current

          if (canvas) {
            const ctx = canvas.getContext("2d") as CanvasRenderingContext2D
            canvas.width = 3000
            canvas.height = 3000

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            const resizedImage = canvas.toDataURL("image/jpeg")

            setLoading(true)
            const resp = await users(`/artist_album_tracks_spotify/?album_id=${album.id}`, "GET")

            if (resp.data.results.length === 0) {
              setErrorText(lang.catalog.new_release.error.error_on_finding_result)
              setLoading(false)
              return reject("No results found")
            }

            const spotifyData = resp.data.results
            const optionResponse = await releases(`/release/label_camp_options/`, "get")
            const labelOptions = optionResponse.data["label_options"]

            const productTypeRelationship = {
              "product-type": { data: { type: "product-types", id: spotifyData["total_tracks"] === 1 ? "2" : "1" } }
            }

            const productDataRelationships =
              labelOptions?.length >= 1
                ? { ...productTypeRelationship, label: { data: { id: labelOptions[0].value, type: "labels" } } }
                : { ...productTypeRelationship }

            const labelName = labelOptions[0].label
            const productionYear = spotifyData["release_date"].substring(0, 4)
            const label = spotifyData["label"].trim()

            const releaseForm: LCReleaseForm = {
              product: {
                data: {
                  id: undefined,
                  attributes: {
                    name: spotifyData["name"],
                    "upc-code": spotifyData["upc"],
                    "original-release-date": new Date(spotifyData["release_date"]),
                    "production-year": productionYear,
                    "copyright-line": `${productionYear} (C) ${label}`,
                    "production-line": `${productionYear} (P) ${label}`,
                    artist: spotifyData["artists_list"],
                    "front-cover": resizedImage
                  },
                  relationships: productDataRelationships,
                  type: "products"
                }
              },
              offer: { data: { id: undefined, attributes: {}, relationships: { product: {} }, type: "offers" } },
              tracks: [],
              import_tasks: [],
              import_sub_tasks: [],
              records: [],
              track_offers: [],
              logs: []
            }

            await saveProductAndTracks(releaseForm, labelName, spotifyData["track_list"], isMulti)
            resolve()
          }
        } catch (err) {
          setErrorText(err)
          setLoading(false)
          reject(err)
        }
      }

      img.onerror = (err) => {
        reject(err)
      }
    })
  }

  const onTakedownMultiple = async () => {
    console.log(albums)
    for (let i = 0; i < albums.length; i++) {
      await onSelectAlbumForTakedown(albums[i], true)
    }

    setTimeout(() => {
      navigate(`/catalog/`)
    }, 250)
  }

  const handleConfirmAlbumSelect = async () => {
    if (!albumToSelect) {
      return
    }
    onSelectAlbumForTakedown(albumToSelect, false)
  }

  const renderAlbums = () => {
    return albums
      .filter((album) => album.name.toLowerCase().includes(searchAlbumText.toLowerCase()))
      .map((album) => {
        const songLabel =
          album.totalTracks > 1 ? `${album.totalTracks} ${lang.common.songs_uppercase}` : lang.common.single_uppercase
        return (
          <div className={`takedown-album-div cursor-pointer`} key={album.id} onClick={() => setAlbumToSelect(album)}>
            <div className="flex gap-4">
              <img src={album.cover} style={{ width: 40, height: 40, borderRadius: 6 }} />
              <KTitleSpan text={album.name} fontSize={20} lineHeight="28px" fontWeight={500} />
            </div>
            <div className="flex gap-2" style={{ alignItems: "center" }}>
              <div className="takedown-album-pill-div">
                <KSpan text={selectedArtist?.label?.toString() || "-"} fontSize={11} color="#000" />
              </div>
              <div className="takedown-album-pill-div">
                <KSpan text={album?.releaseDate || "-"} fontSize={11} color="#000" />
              </div>
              <div className="takedown-album-pill-div">
                <KSpan text={songLabel} fontSize={11} color="#000" />
              </div>
            </div>
          </div>
        )
      })
  }

  const renderArtistSelect = () => {
    return (
      <div>
        <div style={{ marginTop: 12 }}>
          <KSpan text={lang.catalog.new_release.spotify_artist_selection_desc} />
        </div>
        <div className={"mt-6"}>{renderSearchBox()}</div>
      </div>
    )
  }

  const renderAlbumSelect = () => {
    if (!selectedArtist) {
      return <div />
    }

    return (
      <div>
        {isUpload && (
          <div className="flex justify-between mt-4">
            <KTitleSpan fontSize={16} lineHeight="20px" text="Upload Csv File exported from Scouting Confirmed Offer" />
            <KButton
              width="200px"
              textColor="black"
              background="transparent"
              text="+Upload File"
              //@ts-ignore
              onClick={() => fileInputRef?.current && fileInputRef.current.click()}
            />
            <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
          </div>
        )}
        <div style={{ marginTop: 24 }}>
          <KInput
            value={searchAlbumText}
            onChange={(value) => setSearchAlbumText(value)}
            placeholder={lang.catalog.new_release.album_name_placeholder}
            leftIcon={"/search.svg"}
            padding="14px"
            gap="8px"
          />
        </div>
        <div className={"mt-6 mb-6 pb-2"} style={{ border: "1px solid #F3F3F3", borderRadius: 10 }}>
          {renderAlbums()}
        </div>
        {hasNext && (
          <div className={"pb-6 flex justify-center"}>
            <KButton
              text={lang.catalog.new_release.load_more}
              width="160px"
              background="white"
              onClick={() => onLoadMore()}
              disabled={loading}
            />
          </div>
        )}
        {isUpload && albums?.length > 0 && (
          <div className={"pb-6 flex justify-center"}>
            <KButton
              text={lang.catalog.new_release.takedown_all}
              width="160px"
              background="white"
              onClick={() => onTakedownMultiple()}
              disabled={loading}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="flex gap-6">
      {loading && <Loading />}
      <ErrorModal text={errorText} setText={setErrorText} />
      <div className="cursor-pointer mt-3" onClick={() => setSelectedPage(NewReleaseActions.FINISH_RELEASE)}>
        <img src="/analytics_icons/caret-left.svg" style={{ borderRadius: 10, padding: 8, background: "#F7F7F7" }} />
      </div>
      <div>
        <div>
          <KTitleSpan text={lang.catalog.new_release.move_album} fontSize={32} />
        </div>
        {selectedTab === TakedownActionTabs.SPOTIFY_ARTIST && renderArtistSelect()}
        {selectedTab === TakedownActionTabs.SELECT_ALBUM && renderAlbumSelect()}
      </div>
      <ConfirmationDialog
        openConfirmation={albumToSelect !== undefined}
        setOpenConfirmation={() => setAlbumToSelect(undefined)}
        handleConfirm={handleConfirmAlbumSelect}
        loading={loading}
        overrideDescription={lang.catalog.new_release.takedown_confirmation_desc.replace(
          "<param>",
          albumToSelect?.name || "-"
        )}
      />
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  )
}

export default Takedown
